import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Seo from '../components/basic/seo'
import BackgroundImage from "gatsby-background-image"
import Loginform from '../components/loginform'
import Breadcrumb from '../components/basic/breadcrumb'

export default function Login() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "home_background.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage
        Tag="section"
        className="container-fluid"
        fluid={imageData}
        style={{ height: '100vh', backgroundPosition: 'bottom' }}
      >
      <Seo title="login" />
      <div className="container">
        <div className="row d-flex align-items-center" style={{ height: '86vh' }}>
          <div className="col" style={{padding: '2rem'}}>
            <h1 className="text-hide text-light">
              <strong style={{fontWeight: '800'}}>COSBIM</strong> <small>360</small>
            </h1>
            <h3 className="text-light">La inmobiliaria refente en oficinas de calidad de la zona euro.</h3>
            <div className="pt-5">
              <Breadcrumb />
            </div>
          </div>
          <div className="col">
          <div className="card" 
            style={{backgroundColor: 'rgba(184, 190, 191, 0.9)', padding: '1rem'}}
          >
            <div className="card-body">
              <Loginform />
            </div>
          </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
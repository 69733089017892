import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import { Navigate } from 'react-router-dom';


class Loginform extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
  }

  render() {
    if (typeof window !== 'undefined'){
      if (isLoggedIn()) {
        return <Navigate to="/app/madrid/profile/1" />
      }
    }
    
    return (
      <>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
            navigate(`/app/madrid/profile/1`)
          }}
        >
        <div className="mb-3">
        </div>
        <div className="mb-3">
          <label htmlFor="user" className="form-label">Usuario</label>
          <input 
            style={{opacity: 0.5}}
            type="text" 
            className="form-control form-control-lg" 
            name="username"
            id="user"
            onChange={this.handleUpdate}
          >
          </input>
        </div>
        <div className="mb-3">
          <label htmlFor="pass" className="form-label">Contraseña</label>
          <input
            className="form-control form-control-lg"
            style={{opacity: 0.5}}
            type="password"
            name="password"
            id="pass"
            onChange={this.handleUpdate}
          >
          </input>
        </div>
        <div className="d-grid gap-2">
          <input className="btn btn-primary" type="submit" value="Entrar"></input>
        </div>
        </form>
      </>
    )
  }
}
export default Loginform